import React from 'react'
import Loading from 'icons/Loading'

const LoadingButton = ({ loading, disabled, className, children, type = 'submit', onClick = () => {}, ...props }) => {
  return (
    <button
      className={`loadingButton ${className}`}
      type={type}
      disabled={disabled || loading}
      onClick={onClick}
      {...props}
    >
      {loading ? <Loading height={'1.25rem'} /> : children}
    </button>
  )
}

export default LoadingButton
