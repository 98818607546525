import React from 'react'
import Link from 'next/link'
import { observer } from 'mobx-react-lite'
import { useRouter } from 'next/router'
import { isServer } from 'utils'
import { useStore } from 'hooks'
import { Formik, Field, Form, ErrorMessage } from 'formik'
import * as Yup from 'yup'

import { AuthStore } from 'stores/auth'
import { NavigationStore } from 'stores/navigation'
import { UserStore } from 'stores/user'

import LoadingButton from 'components/LoadingButton'
import Loading from 'icons/Loading'
import { track } from 'lib/whoami'

const LoginSchema = Yup.object().shape({
  email: Yup.string().email('Please enter a valid email').required('This field is required'),
  password: Yup.string().min(4, 'Password must be at least 4 characters').required('This field is required')
})

const LoginForm = () => {
  const router = useRouter()
  const authStore = useStore(AuthStore)
  const userStore = useStore(UserStore)
  const navigationStore = useStore(NavigationStore)

  if (authStore.loggedIn) {
    router.replace('/')
    return <Loading />
  }

  const handleSubmit = async (values, actions) => {
    actions.setSubmitting(true)
    try {
      const { user } = await authStore.login(values.email, values.password)
      await userStore.findById('me')
      track('AccountLogin', {
        user: {
          barstoolUserID: String(user.id),
          email: user.email
        }
      })
      router.push(navigationStore.postAuthRedirect)
    } catch (error) {
      actions.setSubmitting(false)
      actions.setStatus({ message: error.message })
    }
  }
  return (
    <>
      <Formik
        initialValues={{ email: '', password: '' }}
        validationSchema={LoginSchema}
        validateOnBlur={false}
        validateOnChange={false}
        onSubmit={handleSubmit}
        render={({ status, isSubmitting }) => (
          <Form className='commonForm !pb-0'>
            {status && status.message && <div className='form__error'>{status.message}</div>}
            <Field type='email' name='email' placeholder='Email' />
            <ErrorMessage name='email' component='div' className='field__error' />
            <Field type='password' name='password' autoComplete='current-password' placeholder='Password' />
            <ErrorMessage name='password' component='div' className='field__error' />
            <LoadingButton className='btn' loading={isSubmitting}>
              Submit
            </LoadingButton>
            <p className='forgotPassword'>
              <Link href='/account/forgot-password' legacyBehavior>
                <a>Forgot Password?</a>
              </Link>
            </p>
          </Form>
        )}
      />
      <style jsx>{`
        .forgotPassword {
          text-align: center;
          margin-top: 1rem;
        }
        .forgotPassword a {
          color: #007aff;
          font-size: 12.8px;
          letter-spacing: 0.2px;
          line-height: 12px;
          text-decoration: none;
        }
      `}</style>
    </>
  )
}

export default observer(LoginForm)
