import React from 'react'
import Link from 'next/link'
import Head from 'components/Head'
import SiteLayout from 'components/SiteLayout'
import LoginForm from 'components/LoginForm'
import { usePageView } from 'hooks'
import { getStaticWrapper } from 'utils'

const SigninPage = () => {
  usePageView()
  return (
    <>
      <SiteLayout>
        <Head title='Sign In | Barstool Sports' noIndexNoFollow />
        <div className='container container--accountInfoFlow'>
          <h1 className='title'>Login</h1>
          <p className='subtitle'>
            Enter your email address and <br />
            password to login.
          </p>
          <LoginForm />
          <div className='flex flex-col items-center justify-center pt-2 pb-3'>
            <p className='font-semibold'>Don't have an account?</p>
            <Link href='/account/signup' className='w-full btn btn--secondary max-w-[16.25rem]'>
              Join Now
            </Link>
          </div>
        </div>
      </SiteLayout>
      <style jsx>{`
        .subtitle {
          font-size: 1rem;
          margin-top: 0;
        }
        input {
          margin: auto;
          max-width: 16rem;
        }

        .btn--secondary {
          width: 100%;
          max-width: 16.25rem;
        }
        button {
          margin: auto;
          max-width: 16rem;
        }
        h1 + p {
          margin-top: -0.5rem;
          margin-bottom: 24px;
        }
      `}</style>
    </>
  )
}

export const getStaticProps = getStaticWrapper()

export default SigninPage
